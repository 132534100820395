const configuration = {
  configuration: {
    generalData: "Datos generales",
    organizationName: "Nombre de la organización",
    industryType: "Tipo de industria",
    language: "Idioma",
    languages: {
      en: "Inglés",
      es: "Español",
      pr: "Portugués",
      pt: "Portugués",
    },
    newValue: "Nuevo valor",
    newValueLengs: {
      es: "Nuevo valor",
      en: "New value",
      pt: "Novo valor",
    },
    segmentRequired: "Complete al menos un campo de idioma",
    country: "Pais",
    title: {
      general: "General",
      dataAnalysis: "Análisis de datos",
      preferences: "Configuración de envíos",
    },
    timezone: "Zona horaria",
    integrations: {
      title: "Integraciones",
      description:
        "Puedes customizar las configuraciones de tus integraciones.",
    },
    sendPreferences: {
      title: "Canales de comunicación",
      description:
        "Puedes cambiar las preferencias y canales para la comunicación",
      slack: "Permitir envio de interacciones a través de Slack",
      slackTooltip: "Debes estar conectado a Slack para activar esta opción",
      mail: "Permitir envio de interacciones a través de Mail",
      msTeams: "Permitir envio de interacciones a través de MS Teams",
    },
    onboarding: {
      title: "Onboarding",
      description: "Puedes elegir el tipo de onboarding",
      onboardingSwitch: {
        title: "Activar onboarding para colaboradores nuevos",
        input: "Selecciona la encuesta",
      },
      infoDaysSwitch: {
        title: "Retrasa la participación de las micro encuestas",
        input: "Selecciona los días",
      },
      selectInfoSwitch: {
        title: "Pedir información personal al incio del onboarding",
        input: "Selecciona las propiedades",
        teamsInfo: "Pedir informacion sobre equipo",
        tooltip:
          "Para pedir información personal al inicio del onboarding necesitas tener el onboarding activado",
      },
      tabs: {
        all: "Todos",
        bozTemplates: "Plantillas Boz",
        templates: "Plantillas",
      },
      selectAll: "Seleccionar todo",
      standards: "Estándares",
      personalizated: "Personalizadas",
      other: "Otros",
      modal: {
        title: "Confirmar nueva configuración",
        content:
          "¿Está seguro de que desea guardar esta nueva configuración para los futuros colaboradores?",
      },
    },
    recognitionsPreferences: {
      title: "Reconocimientos",
      description: "Configura la frecuencia del reporte de reconocimientos",
      businessRecognitions: "Reconocimientos por empresas",
      teamRecognirions: "Reconocimientos por equipo",
      automaticSendRecognitions: "Envío automático de reconocimientos",
      active: "Activo",
      inactive: "Inactivo",
      addValue: "Agregar valor",
      settingChannel: "Activar Reconocimientos Públicos",
      settingSchedule: "Asignar hora de envío de reconocimientos",
      realTime: "Tiempo real",
      hour: "Hora",
      saveMessage: "Preferencias de envío de reconocimientos guardado",

      sendRecognitionRequestCta: "Enviar pedido de reconocimientos",
      sendRecognitionReportCta: "Enviar reporte de reconocimientos",
      recognizableUsers:
        "Se enviara pedido de reconocimiento a {{total}} colaboradores",
      reportableUsers: "Se enviará un mensaje a los colaboradores reconocidos",
      recognitionRequestSent: "Pedido de reconocimientos enviado exitosamente",
      recognitionReportSent: "Reporte de reconocimientos enviado exitosamente",
      repeatedRecognitionValues:
        "Tienes valores de reconocimiento o íconos repetidos",
      repeatedRecognitionValuesBackCta: "Volver",
      recogntionValueLength: "{{length}} caracteres de máximo {{total}}",
      recognitionValuesSave: "¿Seguro de que quieres guardar la configuración?",
      recognitionValuesSaveWarning:
        "¿Estás seguro de que quieres modificar las categorías?",
      recognitionValuesSaveSubtitle:
        "Si haces esto, dejarás de ver las que elimines y se agregarán las nuevas. Verás las últimas categorías asignadas",
      recognitionValuesSaveCta: "Guardar",
      recognitionValuesCancelCta: "Cancelar",
      tooltipDisableButton:
        "Para utilizar el pedido manual de reconocimiento, primero debes deshabilitar el envío automático de reconocimiento",
      notSendRequestModal: {
        title: "El envío automático de reconocimiento esta activado",
        subtitlePartOne:
          "Para enviar un reconocimiento manual, desactiva el envío de renococimiento automático en configuraciones o haciendo ",
        subtitlePartTwo: " e intenta nuevamente",
        clickHere: "click aquí",
      },
      sendRequestModal: {
        title:
          "El pedido de reconocimientos se enviara ahora a {{numberOfUsers}} colaboradores",
        subtitle:
          "Seleccione la fecha y hora de envío del reporte de reconocimientos",
      },
      someRecognitionWhenSent:
        "Ya se ha enviado un pedido de reconocimiento este mes",
      loadCustomEmoji: "Cargar emojis de valores personalizados",
    },
    emojiLoader: {
      title: "Importa tus emojis",
      explanation:
        "Es mejor usar imágenes cuadradas de menos de 128 KB, que tengan fondo transparente de tipo png, jpg o jpeg. Si tu imagen es demasiado grande, intentaremos cambiarle el tamaño",
      errorType: "El tipo de imagen no es el adecuado!",
      saveSuccess: "Emoji guardado con exito!",
      saveError:
        "No se pudo guardad el emoji, reintenta nuevamente o contacta con soporte",
    },
    maxRecognition: {
      monthlyRecognitions: "Cantidad de reconocimientos mensual",
      maximumPerColaborator: "Máximo por colaborador",
      nRecognitions: "{{ nRecognitions }} reconocimientos",
      unlimited: "Sin limite",
    },
    surveys: {
      surveyTitle: "Programa tus encuestas",
      frequency: "Frecuencia de envío de encuestas",
      preferSchedule: "Horario preferido de envío",
      preferenceDays: "Días de envío",
      questionQuantity: "Cantidad de preguntas",
    },
    slack: {
      connect: "Conectar con Slack",
      alreadyConnected: "Ya estás conectado a Slack",
      automaticTeamJoin: "Añadir usuarios nuevos automáticamente",
      automaticInfoUpdate: "Actualización automática de los usuarios",
      tooltips: {
        automaticTeamJoin:
          "Si está encendido, cada vez que una persona nueva ingrese a tu espacio de trabajo en Slack, se agregará automáticamente a Burnout-Zero",
        automaticInfoUpdate:
          "Si está encendido, mantendrá sincronizada la información de los usuarios cada vez que la cambien en Slack (foto, nombre, etc)",
      },
    },
    breadcrumbs: {
      settings: "Configuración",
      properties: "Propiedades",
      organizationDetails: "Detalles de la organización",
      integrations: "Integraciones",
      sendPreferences: "Canales de comunicación",
      recognitionsPreferences: "Reconocimientos",
      segments: "Segmentos",
      newSegment: "Nuevo segmento",
      birthdayBot: "BirthdayBoz",
      onboarding: "Onboarding",
    },
    teams: {
      connect: "Conectar con MS Teams",
      alreadyConnected: "Ya estás conectado a Microsoft Teams",
      upgradeIntegration: "Actualizar integración",
      upgradingIntegration: "Actualizando integración",
      updatePermissions: "Actualizar permisos",
      upgradeSuccesful: "Actualización exitosa",
      setPermissions: "Autorizar nuevos permisos",
      backToHome: "Volver al inicio",
    },
    sections: {
      general: {
        name: "General",
        subsections: {
          details: {
            name: "Detalles de la organización",
            description:
              "Actualiza la información de tu organización e idioma.",
          },
          integrations: {
            name: "Integraciones",
            description: "Revisa el estado de tus integraciones.",
          },
        },
      },
      preferences: {
        name: "Configuración de envíos",
        subsections: {
          sendPreferences: {
            name: "Canal de comunicación",
            description: "Configura el canal de envío para tus comunicaciones.",
          },
          recognitions: {
            name: "Reconocimientos",
            description:
              "Configura tus valores organizacionales a la hora de reconocer.",
          },
          calendar: {
            name: "Calendario",
            description:
              "Configura la frecuencia y configuración de tus envíos.",
          },
          birthdayBot: {
            name: "BirthdayBoz",
            description: "¡Que nunca más se te pase un cumpleaños!",
          },
          onboarding: {
            name: "Onboarding",
            description: "Configura el onboarding para colaboradores nuevos.",
          },
        },
      },
      data: {
        name: "Análisis de datos",
        subsections: {
          properties: {
            name: "Propiedades",
            description:
              "Crea y edita propiedades para segmentar a tus colaboradores.",
          },
          filters: {
            name: "Segmentos",
            description:
              "Segmenta a tus colaboradores para analizar en base a tus criterios.",
          },
        },
      },
    },
    properties: {
      title: "Propiedades",
      description:
        "Tienes que crear una propiedad primero para segmentar a los miembros de tu organización.",
      searchBar: {
        placeholder: "Busca una propiedad",
      },
      table: {
        headers: {
          name: "Nombre",
          type: "Tipo",
          createdBy: "Creado por",
          category: "Categoría",
        },
        badge: {
          custom: "Personalizada",
          standard: "Estándar",
        },
        types: {
          Date: "Fecha",
          Options: "Opciones",
          Numeric: "Numérico",
          Text: "Texto",
        },
        buttons: {
          delete: "Borrar propiedad",
          edit: "Editar propiedad",
        },
      },
      createProperty: {
        title: "Nueva propiedad",
        description:
          "Esto te permitirá segmentar los miembros de tu organización",
        name: "Nombre de la propiedad",
        type: "Tipo de la propiedad",
        values: "Valores de la propiedad",
        placeholder: "Ej: Desarrollo",
        addValue: "Agregar nuevo valor",
        create: "Crear propiedad",
        nameError: "Ingresa un nombre para la propiedad",
      },
      assignProperties: {
        button: "Asignar",
        body: {
          title: "Asignar propiedades a usuarios",
          tipsTitle: "Antes de subir la planilla, asegurate que:",
          tipOne: "Las propiedades de tipo fecha vengan en formato AAAA/MM/DD.",
          tipTwo:
            "Las propiedades de tipo númerico sean un número entero sin decimales.",
          tipThree:
            "Para las propiedades de tipo opción, que el valor que ingresas efectivamente está creado dentro de la propiedad (ej: Masculino).",
          downloadExample: "Descargar plantilla ejemplo",
          upload: "Subir propiedades",
        },
      },
      propertiesValidationError: {
        title: "Hubo un error",
        caption: "Revisa lo siguiente",
        invalid: "Propiedades no existentes",
        valid: "Propiedades correctas",
        tip: "Revisa que las propiedades no existentes estén creadas en la cuenta y/o que estén bien escritas en la planilla.",
        retry: "Reintentar",
      },
      editProperty: {
        title: "Editar propiedad",
        description:
          "Puedes cambiar solo el nombre de las propiedades existentes.",
        name: "Nombre de la propiedad",
        type: "Tipo de la propiedad",
        values: "Valores de la propiedad",
        typeForbidden: "No se permite cambiar el tipo de la propiedad",
        update: "Actualizar propiedad",
        addValue: "Agregar nuevo valor",
      },
      deleteProperty: {
        title: "¿Borrar esta propiedad?",
        description:
          "Si borras esta propiedad, todos los segmentos que tengan asociada esta propiedad se borrarán.",
        accept: "Aceptar",
        cancel: "Cancelar",
      },
      importResult: {
        title: "Resultado de la importación",
        email: "Email",
        rowNumber: "# de fila",
        retry: "Reintentar",
        resume:
          "Revisa el resultado de tu importación para que puedas corregir si hay errores:",
        NUMERIC_VALUE_INVALID: "El valor numérico ingresado es inválido",
        INVALID_OPTION_VALUE:
          "La opción ingresada como valor no existe en la propiedad",
        DATE_FORMAT_INVALID: "El formato de fecha ingresado es inválido",
        USER_DOESNT_EXIST: "El usuario ingresado no existe en la cuenta",
        EMAIL_EMPTY: "El campo email está vacío",
        VALUE_EMPTY: "El campo está vacío",
      },
    },
    organizationDetails: {
      title: "Detalles de la organización",
      description: "Puedes editar la información general de tu organización.",
    },
    segments: {
      title: "Segmentos",
      description:
        "Monitorea diferentes agrupaciones de miembros en función de rasgos y características comunes.",
      button: "Crear",
      table: {
        headers: {
          name: "Segmento",
          properties: "Propiedades",
          members: "Miembros",
        },
        tooltip:
          "Este segmento no estará disponible en los filtros al tener menos de 5 miembros activos.",
      },
      deleteModal: {
        title: "Borrar segmento",
        description: "¿Estás seguro que quieres eliminar este segmento?",
        accept: "Aceptar",
        cancel: "Cancelar",
      },
      conditions: {
        IS_BEFORE: "es antes de",
        IS_EXACTLY: "es exactamente",
        IS_AFTER: "es después de",
        IS_EQUAL_TO: "es igual a",
        IS_NOT_EQUAL_TO: "no es igual a",
        IS_LESSER_THAN: "es menor a",
        IS_GREATER_THAN: "es mayor a",
      },
      viewSegment: {
        property: "Propiedad",
        condition: "Condición",
        value: "Valor",
      },
    },
    newSegment: {
      title: "Crea un nuevo segmento",
      description:
        "Usa segmentos para agrupar tus colaboradores en base a tus propiedades.",
      emptyState: {
        title: "Crea segmentos para identificar tendencias de grupo",
        description:
          "Puedes monitorear diferentes grupos de colaboradores en base a sus características y rasgos comunes.",
        button: "Quiero saber más",
      },
      createForm: {
        name: {
          label: "Nombre del segmento",
          placeholder:
            "Elige un nombre para el segmento, ej: Hombres menores de 30",
        },
        rules: {
          title: "Reglas y propiedades",
          select: {
            all: "Todas",
            any: "Cualquiera",
            hasTo: "Tiene que cumplir",
            following1: "las siguientes",
            following2: "de las siguientes",
          },
        },
        propertyRules: {
          property: "Propiedad",
          condition: "Condición",
          value: "Valor",
          add: "Agregar nueva regla",
          button: "Crear segmento",
          nameHelperText: "Ingresa un nombre para el segmento",
          alerts: {
            noUsers:
              "No hay usuarios asociados a este segmento. Intenta modificar o agregar reglas.",
            oneUser:
              "Solo hay {{active}} usuario activo asociado a este segmento y {{inactive}} usuarios inactivos",
            lessThanFiveUsers:
              "Solo hay {{active}} usuarios activos asociados a este segmento y {{inactive}} usuarios inactivos.",
            ok: "OK! Hay {{active}} usuarios activos y {{inactive}} usuarios inactivos asociados a este segmento.",
          },
        },
      },
    },
    birthdayBot: {
      title: "BirthdayBoz",
      description: "Configura tu bot cumpleañero.",
      activate: "Activar bot cumpleañero",
      birthdays: "Enviar cumpleaños",
      anniversaries: "Enviar aniversarios de trabajo",
      selectGroup: "Elige tu equipo de envío",
      textToLoad: "Carga tus aniversarios y cumplaños con Excel",
      selectChannel: "Elige tu canal de envío",
      selectSchedule: "Elige tu horario de envío",
      disclaimer: "*El mensaje se enviará según la zona horaria de la cuenta",
      informativeParagraph:
        "¡Le invitamos a configurar la zona horaria de su cuenta para acceder a las celebraciones de Birthday BOZ. Por favor, haga clic ",
      here: "aquí!",
      upload: {
        title: "Asignar aniversarios y cumpleaños a usuarios",
        subTitle: "Información importante sobre BirthdayBOZ :",
        paragraph1:
          "Las fechas se deben ingresar en formato AAAA/MM/DD en tipo de texto.",
        paragraph2:
          "Los usuarios también podrán cargar sus fechas desde el canal de comunicación, reemplazando las fechas importadas a través de la planilla.",
        paragraph3:
          "Cada día en el que se cumpla un cumpleaños o aniversario, BirthdayBOZ enviará un mensaje de celebración automatízado al canal seleccionado.",
        file: "Subir archivo",
        results: "Resultados de importación",
        tryAgain: "Importar de nuevo",
        button: "Cargar fechas con Excel",
        errors: {
          INVALID_DATE: "Fecha inválida",
          USER_DOESNT_EXIST: "Usuario no existe en la cuenta",
        },
        headers: {
          row: "N° de fila",
          email: "Email",
          comments: "Comentarios",
        },
      },
    },
  },
};
export default configuration;
