const configuration = {
  configuration: {
    generalData: "General data",
    organizationName: "Organization's name",
    industryType: "Industry type",
    language: "Language",
    languages: {
      en: "English",
      es: "Spanish",
      pr: "Portuguese",
      pt: "Portuguese",
    },
    newValue: "New value",
    newValueLengs: {
      es: "Nuevo valor",
      en: "New value",
      pt: "Novo valor",
    },
    segmentRequired: "Complete at least one language field",
    country: "Country",
    title: {
      general: "General",
      dataAnalysis: "Data Analysis",
      preferences: "Send preferences",
    },
    timezone: "Timezone",
    integrations: {
      title: "Integrations",
      description: "You can customize the configurations of your integrations.",
    },
    sendPreferences: {
      title: "Communication channels",
      description:
        "You can change the preferences and channels for your communication.",
      slack: "Enable sending interactions through Slack",
      slackTooltip: "You must be connected to Slack to turn on this option",
      mail: "Enable sending interactions through Mail",
      msTeams: "Enable sending interactions through Teams",
    },
    onboarding: {
      title: "Onboarding",
      description: "You can choose onboarding preferences",
      onboardingSwitch: {
        title: "Enable onboarding for new employees",
        input: "Select survey",
      },
      infoDaysSwitch: {
        title: "Delay microsurveys participacion",
        input: "Select number of days",
      },
      selectInfoSwitch: {
        title: "Request personal information at the start of onboarding",
        input: "Select the properties",
        teamsInfo: "Request information about the team",
        tooltip:
          "To request personal information at the start of onboarding, you need to have onboarding enabled",
      },
      tabs: {
        all: "All",
        bozTemplates: "Boz Templates",
        templates: "Templates",
      },
      selectAll: "Select all",
      standards: "Standards",
      personalizated: "Personalized",
      other: "Other",
      modal: {
        title: "Confirm New Configuration",
        content:
          "Are you sure you want to save this new configuration for future employees?",
      },
    },
    recognitionsPreferences: {
      title: "Recognitions",
      description: "Setting the frequency of recognitions report",
      businessRecognitions: "-",
      teamRecognirions: "-",
      automaticSendRecognitions: "Automatic send recognitions",
      addValue: "Add value",
      active: "Active",
      inactive: "Inactive",
      settingChannel: "Enable Public Recognitions",
      settingSchedule: "Assign time for sending acknowledgements",
      realTime: "Real time",
      hour: "Hour",
      saveMessage: "Saved preference for recognition send",
      sendRecognitionRequestCta: "Send recognition requests",
      sendRecognitionReportCta: "Send recognitions report",
      disablePreferenceWarningCta:
        "Are you sure you want to delete your shipping preferences?",
      disablePreferenceWarningSubtitle:
        "If you remove your preference, automatic delivery will be stopped",
      recognizableUsers:
        "Recognition request will be sent to {{total}} collaborators",
      reportableUsers: "A message will be sent to recognized collaborators",
      recognitionRequestSent: "Recognition request was sent successfully",
      recognitionReportSent: "Recognition report was sent successfully",
      repeatedRecognitionValues: "Repeated recognition values or icons",
      repeatedRecognitionValuesBackCta: "Back",
      recogntionValueLength: "{{length}} characters from maximum {{total}}",
      recognitionValuesSave: "Are you sure you want to save the settings?",
      recognitionValuesSaveWarning:
        "Are you sure you want to modify the categories?",
      recognitionValuesSaveSubtitle:
        "If you do this, you will stop seeing the ones you remove and the new ones will be added. You will see the last assigned categories",
      recognitionValuesSaveCta: "Save",
      recognitionValuesCancelCta: "Cancel",
      tooltipDisableButton:
        "To use the manual recognition request, you first have to disable the automatic recognition submission",
      notSendRequestModal: {
        title: "The sending of automatic acknowledgements is enabled",
        subtitlePartOne:
          "If you need to send a manual acknowledgement, you'll need to disable automatic acknowledgements in the configuration,",
        subtitlePartTwo: " and try again",
        clickHere: " or click here",
      },
      sendRequestModal: {
        title:
          "The recognition request will now be sent to {{numberOfUsers}} collaborators",
        subtitle: "Select the date and time for sending the recognition report",
      },
      someRecognitionWhenSent:
        "A recognition request has already been sent this month",
      loadCustomEmoji: "Load custom value emojis",
    },
    emojiLoader: {
      title: "Import your emojis",
      explanation:
        "It's best to use square images of less than 128 KB with a transparent background in formats such as png, jpg, or jpeg. If your image is too large, we will attempt to resize it.",
      errorType: "Incorrect image type!",
      saveSuccess: "Emoji saved successfully!",
      saveError:
        "Could not save the emoji, please try again or contact support.",
    },
    maxRecognition: {
      monthlyRecognitions: "Monthly recognitions quantity",
      maximumPerColaborator: "Maximum per collaborator",
      nRecognitions: "{{ nRecognitions }} recognitions",
      unlimited: "Unlimited",
    },
    surveys: {
      surveyTitle: "Schedule your surveys",
      frequency: "Surveys send frequency",
      preferSchedule: "Preferred delivery time",
      preferenceDays: "Preferred days",
      questionQuantity: "Question quantity",
    },
    slack: {
      connect: "Connect to Slack",
      alreadyConnected: "You are already connected to Slack",
      automaticTeamJoin: "Automatic team join",
      automaticInfoUpdate: "Automatic info update",
      tooltips: {
        automaticTeamJoin:
          "When on, every time a new person enters your workspace in Slack, they will automatically be added to Burnout-Zero.",
        automaticInfoUpdate:
          "When on, it will keep users information in sync every time they change it in Slack (photo, name, etc.)",
      },
    },
    breadcrumbs: {
      settings: "Settings",
      properties: "Properties",
      organizationDetails: "Organization details",
      integrations: "Integrations",
      sendPreferences: "Communication channels",
      recognitionsPreferences: "Recognitions",
      feedbacksPreferences: "Feedbacks",
      segments: "Segments",
      newSegment: "New segment",
      birthdayBot: "BirthdayBoz",
      onboarding: "Onboarding",
    },
    teams: {
      connect: "Connect to MS Teams",
      alreadyConnected: "You are already connected to Microsoft Team",
      upgradeIntegration: "Upgrade integration",
      upgradingIntegration: "Upgrading integration",
      updatePermissions: "Update permissions",
      upgradeSuccesful: "Upgrade Succesful",
      setPermissions: "Authorize new permissions",
      backToHome: "Back to home page",
    },

    sections: {
      general: {
        name: "General",
        subsections: {
          details: {
            name: "Organization details",
            description:
              "Update the language and information about your organization.",
          },
          integrations: {
            name: "Integrations",
            description: "Check the status of your integrations.",
          },
        },
      },
      preferences: {
        name: "Sending preferences",
        subsections: {
          sendPreferences: {
            name: "Communication channels",
            description:
              "Configure the delivery channel for your communications.",
          },
          recognitions: {
            name: "Recognitions",
            description:
              "Configure your organizational values for your recognitions.",
          },
          calendar: {
            name: "Calendar",
            description:
              "Configure the frequency and settings of your communications. ",
          },
          birthdayBot: {
            name: "BirthdayBoz",
            description: "Never miss a birthday again!",
          },
          onboarding: {
            name: "Onboarding",
            description: "Set up the onboarding for new employees.",
          },
        },
      },
      data: {
        name: "Data analysis",
        subsections: {
          properties: {
            name: "Properties",
            description: "Create and edit properties to segment your members.",
          },
          filters: {
            name: "Segments",
            description:
              "Segment your collaborators to analyze based on your criteria.",
          },
        },
      },
    },
    properties: {
      title: "Properties",
      description:
        "You have to create a property first to segment the members of your organization.",
      searchBar: {
        placeholder: "Search for a property",
      },
      table: {
        headers: {
          name: "Name",
          type: "Type",
          createdBy: "Created by",
          category: "Category",
        },
        badge: {
          custom: "Custom",
          standard: "Standard",
        },

        types: {
          Date: "Date",
          Options: "Options",
          Numeric: "Numeric",
          Text: "Text",
        },
        buttons: {
          delete: "Delete property",
          edit: "Edit property",
        },
      },
      createProperty: {
        title: "Create a property",
        description:
          "This will allow you to segment the members of your organization.",
        name: "Property name",
        type: "Property type",
        values: "Property values",
        placeholder: "e.g. Product",
        addValue: "Add new value",
        create: "Create property",
        nameError: "Enter a name for the property",
      },
      assignProperties: {
        button: "Assign",
        body: {
          title: "Assign properties to users",
          tipsTitle: "Before uploading the sheet, make sure that:",
          tipOne: "Date type properties come in YYYY/MM/DD format.",
          tipTwo: "Numeric type properties are an integer with no decimals..",
          tipThree:
            "For option type properties, that the value you enter is actually created within the property (ex: Male).",
          downloadExample: "Download example sheet",
          upload: "Upload properties",
        },
      },
      propertiesValidationError: {
        title: "There was an error",
        caption: "Check the following",
        invalid: "Non existent properties",
        valid: "Correct properties",
        tip: "Check that the non existent properties are created in the account and/or that they are correctly written in the spreadsheet.",
        retry: "Retry",
      },
      editProperty: {
        title: "Edit property",
        description: "You can only change the name of existing properties.",
        name: "Property name",
        type: "Property type",
        values: "Property values",
        typeForbidden: "It is not allowed to change the type of the property",
        update: "Update property",
        addValue: "Add new value",
      },
      deleteProperty: {
        title: "Delete this property?",
        description:
          "If you delete this property, all segments that have this property associated with them will be deleted.",
        accept: "Accept",
        cancel: "Cancel",
      },
      importResult: {
        title: "Import result",
        email: "Email",
        rowNumber: "# row",
        retry: "Retry",
        resume:
          "Check the result of your import so you can correct if there are any errors:",
        NUMERIC_VALUE_INVALID: "Numeric value entered is invalid",
        INVALID_OPTION_VALUE:
          "The opcion entered as value does not exist in the property",
        DATE_FORMAT_INVALID: "Date format value entered is invalid",
        USER_DOESNT_EXIST: "The entered user doesn't exists in the account",
        EMAIL_EMPTY: "The email field is empty",
        VALUE_EMPTY: "The field is empty",
      },
    },
    organizationDetails: {
      title: "Organization details",
      description:
        "You can edit the general information about your organization.",
    },
    segments: {
      title: "Segments",
      description:
        "Monitor different groups of members based on shared traits and characteristics.",
      button: "Create",
      table: {
        headers: {
          name: "Segment",
          properties: "Properties",
          members: "Members",
        },
        tooltip:
          "This segment will not be available on the filters as it has less than 5 active members.",
      },
      deleteModal: {
        title: "Delete segment",
        description: "Are you sure you want to delete this segment?",
        accept: "Accept",
        cancel: "Cancel",
      },
      conditions: {
        IS_BEFORE: "is before",
        IS_EXACTLY: "is exactly",
        IS_AFTER: "is after",
        IS_EQUAL_TO: "is equal to",
        IS_NOT_EQUAL_TO: "is not equal to",
        IS_LESSER_THAN: "is lesser than",
        IS_GREATER_THAN: "is greater than",
      },
      viewSegment: {
        property: "Property",
        condition: "Condition",
        value: "Value",
      },
    },
    newSegment: {
      title: "Create a new segment",
      description: "Use segments to group members based on your properties.",
      emptyState: {
        title: "Create segments to identify group trends",
        description:
          "You can monitor different groups of members based on common traits and characteristics.",
        button: "Learn more",
      },
      createForm: {
        name: {
          label: "Segment name",
          placeholder:
            "Choose a name for the segment, e.g: Males under 30 years",
        },
        rules: {
          title: "Rules and properties",
          select: {
            hasTo: "Must comply",
            all: "All",
            any: "Any",
            following1: "of the following",
            following2: "of the following",
          },
        },
        propertyRules: {
          property: "Property",
          condition: "Condition",
          value: "Value",
          add: "Add new rule",
          button: "Create segment",
          nameHelperText: "Enter a name for the segment",
          alerts: {
            noUsers:
              "There are no users associated with this segment. Try to modify or add rules.",
            oneUser:
              "There is only {{active}} active user associated with this segment and {{inactive}} inactive users.",
            lessThanFiveUsers:
              "There is only {{active}} active users associated with this segment and {{inactive}} inactive users.",
            ok: "OK! There are {{active}} active users and {{inactive}} inactive users associated with this segment.",
          },
        },
      },
    },
    birthdayBot: {
      title: "BirthdayBoz",
      description: "Configure your birthday bot.",
      activate: "Activate your birthday bot",
      birthdays: "Send birthdays",
      anniversaries: "Send work anniversaries",
      textToLoad: "Load your anniversaries and birthdays with Excel",
      selectGroup: "Choose your sending group",
      selectChannel: "Choose your sending channel",
      selectSchedule: "Choose your schedule",
      disclaimer: "*The message will be sent based on the account's time zone",
      informativeParagraph:
        "¡You are welcome to set your account's time zone to access Birthday BOZ celebrations. Please click ",
      here: "here!",
      upload: {
        title: "Assign anniversaries and birthdays to users",
        subTitle: "Important information about BirthdayBOZ :",
        paragraph1: "Dates must be entered in YYYY/MM/DD format in text type.",
        paragraph2:
          "Users will also be able to upload their dates from the communication channel, replacing the dates imported through the spreadsheet.",
        paragraph3:
          "Each day on which a birthday or anniversary occurs, BirthdayBOZ will send an automated celebration message to the selected channel.",
        file: "Upload file",
        results: "Import results",
        tryAgain: "Import again",
        button: "Load dates with Excel",
        errors: {
          INVALID_DATE: "Invalid date",
          USER_DOESNT_EXIST: "User doesn't exist in the account",
        },
        headers: {
          row: "Row number",
          email: "Email",
          comments: "Comments",
        },
      },
    },
  },
};
export default configuration;
